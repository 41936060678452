// src/pages/Home.tsx
import React from 'react';
import '../styles/Home.css';
import heroImage from '../assets/Home.webp'; // Replace with your uploaded image path

const Home: React.FC = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-overlay">
          <h1>DMSL</h1>
          
          <p>
            We specialize in delivering exceptional educational support services designed to empower your journey.
          </p>
        </div>
      </section>

      {/* Overview Section */}
      <section className="overview">
        <h2>What We Offer</h2>
        <p>
          At thedmsl.com, we provide unparalleled services to ensure your goals are achieved. Our expertise lies in delivering innovative solutions and personalized guidance.
        </p>
      </section>

      {/* Other sections */}
    </div>
  );
};

export default Home;
