// src/pages/About.tsx
import React from 'react';
import '../styles/About.css';
import aboutImage from '../assets/About.webp'; // Replace with your image

const About: React.FC = () => {
  return (
    <section className="about">
      <img src={aboutImage} alt="Our Work" className="about-image" />
      <div className="about-content">
        <h2>About Us</h2>
        <p>
          We are dedicated to providing top-tier support in the field of education, helping individuals achieve their dreams through comprehensive and tailored services. 
          With a team of experts, we bring innovative and effective solutions to the table, ensuring success at every step.
        </p>
      </div>
    </section>
  );
};

export default About;
