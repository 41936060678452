// src/pages/Services.tsx
import React from 'react';
import '../styles/Services.css';
import service1Image from '../assets/Service1.webp'; // Replace with images
import service2Image from '../assets/service2.webp';

const Services: React.FC = () => {
  return (
    <section className="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        <div className="service">

          <img src={service1Image} alt="Service 1" />
          <h3>Personalized Tutoring</h3>
          <p>We offer customized tutoring solutions tailored to individual needs.</p>
        </div>
        <div className="service">
          <img src={service2Image} alt="Service 2" />
          <h3>Career Guidance</h3>
          <p>Professional career advice to help you make informed decisions.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
