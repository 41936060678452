import React from 'react';
import '../styles/Gallery.css';

// Import images directly from the `src/assets` folder
import galleryImage1 from '../assets/gallery-image1.webp';
import galleryImage2 from '../assets/gallery-image2.webp';
import galleryImage3 from '../assets/gallery-image5.jpg';
import galleryImage4 from '../assets/gallery-image4.webp';

const Gallery: React.FC = () => {
  const images = [
    {
      src: galleryImage1,
      alt: 'Students collaborating in a modern classroom',
    },
    {
      src: galleryImage2,
      alt: 'Students working on experiments',
    },
    {
      src: galleryImage3,
      alt: 'Interactive learning activity',
    },
    {
      src: galleryImage4,
      alt: 'Creative workshop in progress',
    },
  ];

  return (
    <div className="gallery">
      <h1 className="gallery-title">Our Gallery</h1>
      <p className="gallery-description">Explore moments from our educational programs</p>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} className="gallery-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
