// src/pages/Contact.tsx
import React from 'react';
import '../styles/Contact.css';

const Contact: React.FC = () => {
  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <p>Reach out to us for any inquiries or assistance.</p>
      <form className="contact-form">
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit">Send Message</button>
      </form>
      <p>Or visit us at:</p>
      <address>
        40 Queens Road, Northampton, England, NN1 3LP
      </address>
    </section>
  );
};

export default Contact;
